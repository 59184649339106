/**
 *  项目的配置文件
 */
export const chatUrl = 'wss://im.mall.zhuflow.com';//客服地址
export const mUrl = 'https://m.mall.zhuflow.com/'//移动端网页地址
export const apiUrl = 'https://api.mall.zhuflow.com/';//接口请求地址

export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const title = '云采购';//浏览器顶部title
export const gdKey = 'cd0c68d28fc9f94f6385adbad851aa7f';//高德web-js key
export const statShowDebug = false;//是否开启统计的调试

/** copyright *** slodon *** version-v4.1 *** date-2022-07-22 ***主版本v4.1**/



